//import scroller from "./scroller";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
let mm = gsap.matchMedia();

mm.add("(min-width: 480px)", () => {
   gsap.set('.footer__box', {
      yPercent: -50
   })

   const uncover2 = gsap.timeline({
      paused: true
   })

   uncover2.to('.footer__box', {
      yPercent: 0,
      ease: 'none'
   });

   ScrollTrigger.create({
      trigger: '.cta',
      //scroller: scroller,
      start: 'bottom bottom',
      end: '+=100%',
      animation: uncover2,
      scrub: true,
      //markers: true,
   });


   gsap.set('.footer__head-logo', {
      scale: 1.2,
      yPercent: 100,
   })
   gsap.to('.footer__head-logo', {
      scale: 1,
      yPercent: 0,
      scrollTrigger: {
         trigger: '.cta',
         //scroller: scroller,
         start: 'bottom bottom',
         end: '+=100%',
         scrub: true,
         //markers: true,
      }
   })


   gsap.set(".social-grid__item", {
      autoAlpha: 0,
      y: 60,
      opacity: 0,
   });

   ScrollTrigger.batch(".social-grid__item", {
      once: true,
      onEnter: elements => {
         gsap.fromTo(elements, {
            autoAlpha: 0,
            y: 60,
            opacity: 0,
         }, {
            autoAlpha: 1,
            y: 0,
            opacity: 1,
            //ease: "power3.out",
            duration: 2,
            ease: "power4.easeOut",
            stagger: 0.30
         });
      },
   });
});


gsap.set(".footer__show", {
   autoAlpha: 0,
   y: 30,
   opacity: 0,
});
ScrollTrigger.batch(".footer__show", {
   once: true,
   onEnter: elements => {
      gsap.fromTo(elements, {
         autoAlpha: 0,
         y: 30,
         opacity: 0,
      }, {
         ease: "power3.out",
         duration: 2,
         autoAlpha: 1,
         y: 0,
         opacity: 1,
         //markers: true,
         //scroller: scroller,
         //stagger: 0.15
         stagger: {
            //from: "center",
            //ease: "power3.out",
            amount: 0.60
         }
      });
   },
});


//var copyEmail = document.querySelector('copy-email');
//copyEmail.onclick = function () {
//   navigator.clipboard.writeText('hi@gladkov.company');
//   return false
//};